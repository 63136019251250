import React from "react";
// import Header1 from "./Header1";
import Header2 from "./Header2";
// import Header3 from "./Header3";
import Footer from "./footer";

const TermsAndConditions = () => {
  return (
    <div style={styles.pageContainer}>
      {/* <Header1 /> */}
      <Header2 />
      {/* <Header3 /> */}
      <div style={styles.container}>
        <div style={styles.textContainer}>
       
          {/* <h2 style={styles.title}>PRIVACY POLICY</h2> */}
          {/* <p style={{fontSize:'14px'}}>Disclaimer: <i > In case of any discrepancy or difference, the English version will take precedence over the translation</i>

</p> */}
          <h4 style={styles.subtitle}>Oneclick Terms of Use</h4>
          <p style={styles.paragraph}>
          This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
          </p>
          <p style={styles.paragraph}>
          This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name \[https://oneclickteck.com/] (“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”)
          </p>
          <p style={styles.paragraph}>
          The Platform is owned by Oneclick Internet Private Limited, a company incorporated under the Companies Act, 1956 with its registered office at Buildings Alyssa, Begonia & Clover, Embassy Tech Village, Outer Ring Road, Devarabeesanahalli Village, Bengaluru – 560103, Karnataka, India and its branch offices at 2nd Floor, Block F (Flora), Embassy Tech Village, Outer Ring Road, Devarabeesanahalli Village, Bengaluru-560103, Karnataka, India and; 447/C, 12th Main, 4th Block, Koramangala, Bengaluru-560034, Karnataka, India (hereinafter referred to as "Oneclick").
          </p>
          <p style={styles.paragraph}>
          Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with Oneclick Internet Private Limited and these terms and conditions including the policies constitute Your binding obligations, with Oneclick.
          </p>
          <p style={styles.paragraph}>
          For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a buyer on the Platform by providing Registration Data while registering on the Platform as Registered User using the computer systems. Oneclick allows the User to surf the Platform or making purchases without registering on the Platform. The term "We", "Us", "Our" shall mean Oneclick Internet Private Limited.
          </p>
          <p style={styles.paragraph}>
          ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Oneclick Policies ((including but not limited to Privacy Policy available at Privacy) as amended from time to time.
          </p>
          <h4 style={styles.subtitle}>Membership Eligibility</h4>
          <p style={styles.paragraph}>
          Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e. under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian.
          </p>
          <p style={styles.paragraph}>
          As a minor if you wish to transact on the Platform, such transaction on the Platform may be made by your legal guardian or parents. Oneclick reserves the right to terminate your membership and / or refuse to provide you with access to the Platform if it is brought to Oneclick's notice or if it is discovered that You are under the age of 18 years and transacting on the Platform.
          </p>
          
          <h4 style={styles.subtitle}>Your Account and Registration Obligations</h4>
          <p style={styles.paragraph}>
          If You use the Platform, You shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide You with access to the Platform.
          </p>
          <p style={styles.paragraph}>
          Your mobile phone number and/or e-mail address is treated as Your primary identifier on the Platform. It is your responsibility to ensure that Your mobile phone number and your email address is up to date on the Platform at all times. You agree to notify Us promptly if your mobile phone number or e-mail address changes by updating the same on the Platform through a onetime password verification.
          </p>
          <p style={styles.paragraph}>
          You agree that Oneclick shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your Account in cases, including, where You have failed to update Your revised mobile phone number and/or e-mail address on the Website Platform.
          </p>
          <p style={styles.paragraph}>
          If You share or allow others to have access to Your account on the Platform (“Account”), by creating separate profiles under Your Account, or otherwise, they will be able to view and access Your Account information. You shall be solely liable and responsible for all the activities undertaken under Your Account, and any consequences there from.
          </p>
          <p style={styles.paragraph}>
          You must maintain confidentiality of the account information and for all the activities that occur under Your account. You must not share your login details, ie, username and password of Your account with any other person, else it would be considered breach of this Terms of Use.
          </p>
          <p style={styles.paragraph}>
          You agree to: (a) ensure that You successfully log out from Your account at the end of each session; and (b) immediately notify Oneclick of any unauthorized use of Your account. If there is reason to believe that there is likely to be a breach of security or misuse of Your account, We may request You to change the password or we may suspend Your account without any liability to Oneclick, for such period of time as we deem appropriate in the circumstances. We shall not be liable for any loss or damage arising from Your failure to comply with this provision.
          </p>
          <p style={styles.paragraph}>
          You will have access to other apps owned by Oneclick group companies through the same login username and password. You agree to have single login access to all the apps owned by Oneclick group companies.
          </p>
          <h4  id="PaymentMethod" style={styles.subtitle}>Communications</h4>
          <p style={styles.paragraph}>
          When You use the Platform or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.
          </p>
          <h4  id="PaymentMethod" style={styles.subtitle}>Platform for Transaction and Communication</h4>
          <p style={styles.paragraph}>
          The Platform enables the Buyer and Seller to transact on the Platform. . Oneclick is not and cannot be a party to or control in any manner any transaction between the Platform's Users.
          </p>
          <h4  id="PaymentMethod" style={styles.subtitle}>Henceforward:</h4>
          <p style={styles.paragraph}>
          All commercial/contractual terms are offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. Oneclick does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Sellers. All discounts, offers (including exchange offers) are by the Seller/Brand and not by Oneclick.
          </p>
          <p style={styles.paragraph}>
          Placement of order by a Buyer with Seller on the Platform is an offer to buy the product(s) in by the Buyer to the Seller and it shall not be construed as Seller's acceptance of Buyer's offer to buy the product(s) ordered. The Seller retains the right to cancel any such order placed by the Buyer, at its sole discretion and the Buyer shall be intimated of the same by way of an email/SMS. Any transaction price paid by Buyer in case of such cancellation by Seller, shall be refunded to the Buyer. Further, the Seller may cancel an order wherein the quantities exceed the typical individual consumption. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity that exceeds the typical individual consumption. What comprises a typical individual's consumption quantity limit shall be based on various factors and at the sole discretion of the Seller and may vary from individual to individual.
          </p>
          <p style={styles.paragraph}>
          We may disclose your personal data to third parties, such as sellers, business partners. This disclosure may be required for us to provide you access to our products and services; for fulfillment of your orders; for enhancing your experience; for providing feedback on products; to collect payments from you; to comply with our legal obligations; to conduct market research or surveys; to enforce our Terms of Use; to facilitate our marketing and advertising activities; to analyze data; for customer service assistance; to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our product and services. We may disclose personal data if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal data to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.
          </p>
          <p style={styles.paragraph}>
          Oneclick does not make any representation or Warranty as to specifics (such as quality, value, salability, etc) of the products or services proposed to be sold or offered to be sold or purchased on the Platform. Oneclick does not implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. Oneclick accepts no liability for any errors or omissions, whether on behalf of itself or third parties.
          </p>
          <p style={styles.paragraph}>
          Oneclick is not responsible for any non-performance or breach of any contract entered into between Buyers and Sellers. Oneclick cannot and does not guarantee that the concerned Buyers and/or Sellers will perform any transaction concluded on the Platform. Oneclick shall not and is not required to mediate or resolve any dispute or disagreement between Buyers and Sellers.
          </p>
          <p style={styles.paragraph}>
          Oneclick does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. You are advised to independently verify the bona fides of any particular User that You choose to deal with on the Platform and use Your best judgment in that behalf.
          </p>
          <p style={styles.paragraph}>
          At no time shall Oneclick hold any right, title or interest over the products nor shall Oneclick have any obligations or liabilities in respect of such contract entered into between Buyers and Sellers. Oneclick is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.
          </p>
          <p style={styles.paragraph}>
          The Platform is only a platform that can be utilized by Users to reach a larger base to buy and sell products or services. Oneclick is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Seller and the Buyer.
          </p>

        </div>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  pageContainer: {
    // fontFamily: "poppins",
    backgroundColor: "lightgrey",
    margin: 0,
    padding: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 10px",
    maxWidth: "900px",
    margin: "0 auto",
    backgroundColor:"white",
    marginBottom:'10px',
    marginTop:'10px'
  },
  textContainer: {
    width: "100%",
    maxWidth: "800px",
    textAlign: "left",
  },
  title: {
    fontSize: "2em",
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#333",
    textTransform: "uppercase",
    letterSpacing: "1.6px",
  },
  subtitle: {
    fontSize: "1.2em",
    fontWeight: "bold",
    margin: "20px 0 10px",
    color: "#555",
  },
  paragraph: {
    fontSize: ".9em",
    lineHeight: "1.6",
    color: "#555",
    textAlign: "justify",
    marginBottom: "20px",
  },
  list: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
  listItem: {
    fontSize: "1em",
    lineHeight: "1.6",
    color: "#555",
    marginBottom: "10px",
  },
};

export default TermsAndConditions;